import {AuthenticatedPage} from "./AuthenticatedPage";
import { Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";


export function ProblemReportView() {

    const {t} = useTranslation();

    const getRegToken = () => {
        let data = localStorage.getItem("REGISTRATION_TOKEN");
        return (data && data.length > 0)? data.substring(0, 15) : ''
    }
    return (
        <AuthenticatedPage>
            <Typography variant='h5'>{t("Kontakta_support")}</Typography>
            <Link color="inherit" variant="body1" href={`mailto:support@facilitylabs.com?subject=Support%20${getRegToken()}&body=%3C${t("Beskriv_problemet")}%3E`} underline="always">
                {t("Maila_support")}
            </Link>
        </AuthenticatedPage>
    )
}