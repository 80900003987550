import { createNotificationRegistration } from "../api/NotificationRegistrationAPI";
import { UserFacility } from "../types/CurrentUserFacilities";
import { NotificationRegistration } from "../types/NotificationRegistration";
import { isEmptyArray } from "./IsEmptyHelpers";

export  const setNotificationRegistration = async (registrationToken:string, facility: UserFacility, settingsData:any[]) => {
    const registration: NotificationRegistration = {
        registrationId: registrationToken,
        facility: `facilities/${facility.id}`,
        invoice: shouldNotifyVisit(settingsData, "INVOICE"),
        revisit: shouldNotifyVisit(settingsData, "REVISIT"),
        free: shouldNotifyVisit(settingsData, "FREE_VEHICLE"),
        preRegistered: shouldNotifyVisit(settingsData, "PRE_REGISTERED"),
        marked: shouldNotifyVisit(settingsData, "RED_LIST"),
        foreign: shouldNotifyVisit(settingsData, "FOREIGN")
    }
    const {data, errors} = await createNotificationRegistration(registration);
    if(!isEmptyArray(errors)) {
        console.warn("Failed to set notification registration")
    }
}

export const unsetNotificationRegistration = async (registrationToken:string, facility: UserFacility) => {
    const registration: NotificationRegistration = {
        registrationId: registrationToken,
        facility: `facilities/${facility.id}`,
        invoice: false,
        revisit: false,
        free: false,
        preRegistered: false,
        marked: false,
        foreign: false
    }
    const {data, errors} = await createNotificationRegistration(registration);
    if(!isEmptyArray(errors)) {
        console.warn("Failed to unset notification registration");
    }
}
    const shouldNotifyVisit = (settingsData: any[], name: string):boolean => {
        return settingsData.find(setting => setting.name === name && setting.notify) !== undefined;
    }
    
    