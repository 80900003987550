import {msalInstance} from "../index";
import {Page} from "../types/Page";

export const acquireToken = async () => {
    const activeAccount = msalInstance.getActiveAccount();
    const allAccounts = msalInstance.getAllAccounts();
    if(!activeAccount && allAccounts.length ===0){
        throw Error("No accounts. User is not logged in")
    }
    const request = {
        scopes: ["https://facilitylabsdevtest.onmicrosoft.com/9108841e-3800-498b-ad01-747b60c29e06/Call.API"],
        account: activeAccount || allAccounts[0]
    }

    return msalInstance.acquireTokenSilent(request).then((authResult) => authResult.idToken
    ).catch((error) => {
        msalInstance.clearCache();
        msalInstance.acquireTokenRedirect({...request, redirectUri: "/", redirectStartPage: window.location.href});
        return '';
    })

}
/**
 * Retrieves the ID token of the currently active account.
 * @returns {string | undefined} The ID token, or undefined if no active account is found.
 * @throws {Error} Throws an error if no active account is found.
 * @deprecated  IdToken is retrieved by acquireToken() method called from within fetchData()
    Calls to getIdToken() should be removed in subsequent refactoring

 */
const getIdToken =  (): string | undefined => {
    return '';
}

/**
 * Generates the authorization header with the ID token.
 * @returns {Headers} The headers containing the authorization token.
 * @deprecated This method is not needed any longer since fetchData sets the headers after calling acquireToken()
    All calls to getIdTokenHeader() should be removed in subsequent refactoring

 */
export const getIdTokenHeader =  (): Headers => {
    const idToken = getIdToken();
    const bearer = `Bearer ${idToken}`;

    const headers = new Headers();
    headers.append("Authorization", bearer);
    headers.append('Content-Type', 'application/json');
    return headers;
}

/**
 * Represents the response from an API call.
 * @template T - The type of data in the response.
 * @interface ApiResponse
 * @property {T[]} data - An array of data objects returned by the API.
 * @property {Page | undefined} [page] - Pagination information (optional).
 * @property {ErrorResponse[]} errors - An array of error responses, if any.
 */
export interface ApiResponse<T>{
    data: T[];
    page?: Page;
    errors: ErrorResponse[];
}

/**
 * Represents the response from an API call containing a single data object and error messages.
 * @template T - The type of data in the response.
 * @interface ApiResponseSingle
 * @property {T} data - The single data object.
 * @property {ErrorResponse[]} errors - An array of error responses.
 */
export interface ApiResponseSingle<T>{
    data: T;
    errors: ErrorResponse[];
}

/**
 * Represents a HAL response containing embedded resources.
 * @template T - The type of embedded resources.
 * @interface HalResponse
 * @property {Object.<string, T[]>} _embedded - The embedded resources.
 * @property {Page | undefined} [page] - The pagination information (optional).
 */
export interface HalResponse<T> {
    _embedded: { [key: string]: T[] };
    page?: Page;
}

/**
 * Extracts embedded resources and pagination information from a HAL response.
 * @template T - The type of embedded resources.
 * @param {HalResponse<T>} halResponse - The HAL response.
 * @param {string} resourceName - The name of the embedded resource.
 * @returns {{ data: T[]; page?: Page }} The extracted embedded resources and pagination information (if available).
 */
export function extractEmbeddedResource<T>(halResponse: HalResponse<T>, resourceName: string): { data: T[]; page?: Page } {
    const data = halResponse._embedded[resourceName] || [];
    const page = halResponse.page;
    return { data, page };
}

/**
 * Represents an error response from an API call.
 * @interface ErrorResponse
 * @property {number} status - The HTTP status code.
 * @property {string} statusText - The HTTP status text.
 * @property {string} entity - The entity related to the error.
 * @property {string} property - The property related to the error.
 * @property {string} invalidValue - The invalid value causing the error.
 * @property {string} message - The error message.
 */
export interface ErrorResponse {
    status: number;
    statusText: string;
    entity: string;
    property: string;
    invalidValue: string;
    message: string;
}

/**
 * Creates an array of ErrorResponse objects from the response and response data.
 * @function createErrorResponse
 * @param {Response} response - The HTTP response object.
 * @param {any} responseData - The response data containing error information.
 * @returns {ErrorResponse[]} An array of ErrorResponse objects.
 */
function createErrorResponse(response: Response, responseData: any): ErrorResponse[] {
    const errors = responseData.errors;
    const errorObjects: ErrorResponse[] = [];

    for (const error of errors) {
        const newError: ErrorResponse = {
            status: response.status,
            statusText: response.statusText,
            entity: error.entity,
            property: error.property,
            invalidValue: error.invalidValue,
            message: error.message
        };
        errorObjects.push(newError);
    }

    return errorObjects;
}

const getHeaders = (token: string) => {
    const idToken = token;
    const bearer = `Bearer ${idToken}`;
    const headers = new Headers();
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json")
    return headers;
}

export async function fetchUnauthenticated(url: string, options?: RequestInit): Promise<ApiResponseSingle<string>> {
    const headers = new Headers();
    headers.append("Content-Type", "text/plain")
    const request = new Request(url, options);
    return fetch(request, {headers: headers})
        .then((response) => {
            if(!response.ok && response.status !== 400) {
                const errorResponse: ErrorResponse[] = createErrorResponse(response, {errors: [{message: response.statusText}]});
                return { data: "", errors: errorResponse};
            }
            else {
                return response.text().then((responseData) => {
                    if(!response.ok) {
                        const errorResponse: ErrorResponse[] = createErrorResponse(response, responseData);
                        return { data: "", errors: errorResponse };
                    }
                    return {data: responseData, errors: []}
                })
            }
        })
}
/**
 * Fetches data from an API endpoint.
 * @template T - The type of data expected in the response.
 * @param {string} url - The URL of the API endpoint.
 * @param {RequestInit} [options] - The options for the fetch request.
 * @param {(responseData: any) => { data: T | T[], page?: Page }} [extractData] - The function to extract data from the response.
 * @returns {Promise<ApiResponse<T> | ApiResponseSingle<T>>} The API response.
 */
export async function fetchData<T>(
    url: string,
    options?: RequestInit,
    extractData = (responseData: any): { data: T | T[], page?: Page } => ({data: responseData})
): Promise<ApiResponse<T> | ApiResponseSingle<T>> {
    return acquireToken()
    .then((token) => {
        const headers = getHeaders(token);
        const request = new Request(url, options);
        return fetch(request, {headers: headers})
        .then((response) => {
            if (!response.ok && response.status !== 400) {
                const errorResponse: ErrorResponse[] = createErrorResponse(response, {errors: [{message: response.statusText}]});
                return { data: [], errors: errorResponse };
            }
            else {
                return response.json().then((responseData) => {
                    if(!response.ok){
                        const errorResponse: ErrorResponse[] = createErrorResponse(response, responseData);
                        return { data: [], errors: errorResponse };
                    } 
                    const { data, page } = extractData(responseData);

                    if (Array.isArray(data)) {
                        return { data, errors: [], page };
                    } else {
                        return { data: responseData, errors: [] };
                    }
                })
            }})
        })
    .catch (reason => {
        console.warn(reason);
        throw Error(reason);
    })
}