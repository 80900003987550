import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import * as React from "react";
import { useState } from "react";
import {
    useTheme,
    Typography,
} from "@mui/material";
import { findHistoricalExemptionByCustomerId, findHistoricalExemptionByFacilityIdIn, findHistoricalExemptionByFacilityIdInAndRegnumberClearLikeOrExemptionIdLikeOrCompanyLike} from "../../api/HistoricalExemptionAPI";
import { useAsync } from "react-use";
import HistoryIcon from "@mui/icons-material/History";
import HistoricalExemptionTable from "./HistoricalExemptionTable";
import Grid from "@mui/material/Grid";
import { useCurrentCustomer } from "../../contexts/CurrentCustomerContext";
import {Facility} from "../../types/Facility";
import FacilityAutocomplete from "../Shared/FacilityAutocomplete";
import SearchBar from "../Shared/SearchBar";
import { HistoricalExemption } from "../../types/HistoricalExemption";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import {TableSettings, TableStorage} from "../../types/settings/TableSettings";

export default function HistoricalExemptions() {
    const {currentCustomer} = useCurrentCustomer();
    const theme = useTheme();
    const [searchQuery, setSearchQuery] = useState("");
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({page: 0, pageSize:TableStorage.loadLocalStorage()});
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: 'updated', sort: 'desc'}])
    const [historicalExemptionItems, setHistoricalExemptionItems] = useState<HistoricalExemption[]>([]);

    const { t } = useTranslation();
    const [facilitiesFilterValue, setFacilitiesFilterValue] = useState<Facility[]>([]);


    const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value;
        setPaginationModel({...paginationModel, page: 0});
        setHistoricalExemptionItems([]);
        setSearchQuery(search);
    };

    const mapSortModelFields = (model: GridSortModel):GridSortModel => {
        if(model.length > 0) {
            const {field, sort} = model[0];
            let sortField;
            if(field === 'regnumber') {
                sortField = 'regnumberClear';
            }
            else if(field === 'company') {
                sortField = 'ownerName';
            }
            else {
                sortField = field;
            }
            return [{field: sortField, sort: sort}];
        }
        else {
            return [];
        }
    };

    const {
        loading,
        error,
        value: totalElements,
    } = useAsync(async () => {
        let result;
        if(searchQuery && facilitiesFilterValue && facilitiesFilterValue.length > 0) {
            result = await findHistoricalExemptionByFacilityIdInAndRegnumberClearLikeOrExemptionIdLikeOrCompanyLike(
                facilitiesFilterValue.map((f) => f.id.toString()),
                encodeURIComponent(searchQuery),
                paginationModel.page,
                paginationModel.pageSize,
                mapSortModelFields(sortModel)
            );
        }
        else if (currentCustomer && searchQuery) {
            result = await findHistoricalExemptionByFacilityIdInAndRegnumberClearLikeOrExemptionIdLikeOrCompanyLike(
                currentCustomer.facilities.map((f) => f.id.toString()),
                encodeURIComponent(searchQuery),
                paginationModel.page,
                paginationModel.pageSize,
                mapSortModelFields(sortModel)
            );
        }
        else if (facilitiesFilterValue && facilitiesFilterValue.length > 0) {
            result = await findHistoricalExemptionByFacilityIdIn(
                facilitiesFilterValue.map((f) => f.id.toString()),
                paginationModel.page,
                paginationModel.pageSize,
                mapSortModelFields(sortModel)
            );
        }
        else if (currentCustomer) {
            result = await findHistoricalExemptionByCustomerId(
                currentCustomer.id.toString(), 
                paginationModel.page,
                paginationModel.pageSize,
                mapSortModelFields(sortModel)
            );
        }
        else {
            result = {data:[], errors:[], page:{totalElements: 0, size: 10, number: 0, totalPages: 0}};
        }
        const {data, errors, page :{totalElements}} = result;

        setHistoricalExemptionItems([...data]);
        return totalElements;
    }, [searchQuery, paginationModel, sortModel, facilitiesFilterValue]);

    const handleFilterFacilitiesChange = (facilities: Facility[]) => {
        setFacilitiesFilterValue(facilities)
    };

    return (
        <Box sx={{height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', gridGap: theme.spacing(2)}}>
            <Box sx={{ display: 'grid', gridGap: theme.spacing(2) }}>
                <Box sx={{ marginBottom: theme.spacing(2), maxWidth:{xs: '100%', sm: '95%'}}}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gridGap: theme.spacing(1) }}>
                        <HistoryIcon/>
                        <h2>{t("Undantagshistorik")}</h2>
                    </Box>
                    <Box sx={{ marginBottom: theme.spacing(2), display: 'flex', gridGap: theme.spacing(1) }}>
                        <Typography>{t("Undantagshistorik_Ledtext")}</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs="auto">
                            <SearchBar
                                label={t("Sök") + ' ' + t("undantag")}
                                helperText={t("Undantagsid_regnummer_eller_företag")}
                                onChange={handleSearchQueryChange}
                                searchQuery={searchQuery}
                                size={"medium"}
                            />
                        </Grid>
                        <Grid item sx={{ marginLeft: { xs: 0, md: "auto"}, pt:1}}>
                            <FacilityAutocomplete
                                label={t("Välj_Anläggningar")}
                                helperText={t("Alla_Anläggningar")}
                                currentCustomer={currentCustomer}
                                limitTags={2}
                                onFacilitiesFilterChange={handleFilterFacilitiesChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{maxHeight: '70%'}}>
                {error && <div>{t("Något gick fel", {error})}</div>}
                {!error && (
                    <HistoricalExemptionTable
                        historicalExemptionItems={historicalExemptionItems}
                        loading={loading}
                        totalElements={totalElements}
                        paginationModel={paginationModel}
                        onPaginationModelChange={(newModel) => {TableStorage.saveLocalStorage(newModel.pageSize);setPaginationModel(newModel)}}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                    />
                )}
            </Box>
        </Box>
    );
}