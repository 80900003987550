import {VehicleData} from "./VehicleData";
import {Exemption} from "./Exemption";
import {BillableVisitType} from "./enums/BillableVisitType";
import { PreRegistration } from "./PreRegistration";

export interface BillableVisit {
    id:string,
    facilityId: number,
    customerId: number,
    sensorId: number,
    vehicleData: VehicleData,
    type: BillableVisitType,
    payableAmount: number,
    soleTrader: boolean,
    foreignHandled: boolean,
    exemption: Exemption,
    clientRef: string,
    reference: string,
    watchListTitle: string,
    watchListComment: string,
    comment: string,
    preRegistration: PreRegistration,
    additionalVehicle: boolean
}

export const toBillableVisitID = (data: any) => {
    const {
        _links: {
            self: {
                href,
            }
        }
    } = data;
    return href.slice(href.lastIndexOf('/') + 1);
}