import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { Navigate } from "react-router-dom";
import { useCurrentUser } from "../contexts/CurrentUserContext";
import { useEffect, useState } from "react";
import { ErrorResponse } from "../api/apiUtils";
import { fetchLoggedInUser } from "../api/UserAPI";
import { isEmptyArray } from "../helpers/IsEmptyHelpers";
import { allAuthorizedUserRoles, flAdminRole } from "../types/CurrentUserRoles";
import { Button, Grid, useTheme } from "@mui/material";
import { AuthenticatedPage } from "../pages/AuthenticatedPage";

export function Home() {
  const isAuthenticated = useIsAuthenticated();
  const {instance} = useMsal();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorResponse[]>([]);
  const {currentUser, setCurrentUser} = useCurrentUser();
  const theme = useTheme();

  useEffect(() => {
    if(isAuthenticated){
        getUser();
    }
  }, [isAuthenticated])


const getUser = async () => {
  try {
    setLoading(true);
    const {data, errors} = await fetchLoggedInUser();
    if(isEmptyArray(errors)){
        setCurrentUser({
            name: data.username,
            fullName: data.fullname,
            customerIds: data.customerIds,
            groups: data.userGroups, 
            roles: data.userRoles,
            isAuthorized: data.userRoles.some(r => allAuthorizedUserRoles.map(role => role.id).includes(r.id)),
            isAdmin: data.userRoles.map(role => role.id).includes(flAdminRole.id),
            facilities: data.userFacilities
        })
    }
    else {
      setCurrentUser({isAuthorized: false, isAdmin: false, roles: [], groups: []});
    }
      setErrors(errors);
    } finally {
      setLoading(false);
    }
  };

  return (
      <>
          <AuthenticatedPage>
            {!loading && currentUser && !currentUser.isAuthorized &&(
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6" align="center">{t("Ej_behörig")}</Typography>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button  onClick={() => instance.logoutRedirect()} color="inherit" variant="text" type="button">{t("Till inlogggningen")}</Button>
              </Grid>
            </Grid>
            )}
            {currentUser && (currentUser.isAdmin || currentUser.customerIds?.length > 1) && <Navigate to="/customers"/>}
            {currentUser && currentUser.isAuthorized && !currentUser.isAdmin && currentUser.customerIds?.length === 1 && <Navigate to={`/customers/${currentUser.customerIds[0]}`}/>}
          </AuthenticatedPage>

      </>
  );
}
