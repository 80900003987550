import Box from "@mui/material/Box";
import {Customer} from "../../types/Customer";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useEffect, useState} from "react";
import {
    useTheme,
} from "@mui/material";
import {fetchCustomers, findCustomerByNameLikeAndActiveIs} from "../../api/CustomerAPI";
import BusinessIcon from "@mui/icons-material/Business";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomersTable from "./CustomersTable";
import Grid from "@mui/material/Grid";
import AddCustomerModal from "./Modals/AddCustomerModal";
import {ErrorResponse} from "../../api/apiUtils";
import Typography from "@mui/material/Typography";
import {isEmptyArray, isEmptyString} from "../../helpers/IsEmptyHelpers";
import SearchBar from "../Shared/SearchBar";
import {useCurrentCustomer} from "../../contexts/CurrentCustomerContext";
import { useCurrentUser } from "../../contexts/CurrentUserContext";
import { Navigate } from "react-router-dom";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import {TableStorage} from "../../types/settings/TableSettings";
import { useDebounce } from "@uidotdev/usehooks";

enum CustomerFilter {
    ALL_CUSTOMERS = "Alla kunder",
    ACTIVE_CUSTOMERS = "Aktiva kunder",
    INACTIVE_CUSTOMERS = "Inaktiva kunder"
}

export default function Customers() {
    const {t} = useTranslation();
    const theme = useTheme();
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearchQuery = useDebounce(searchQuery, 200);
    const [pageModel, setPageModel] = useState<GridPaginationModel>({page: 0, pageSize:TableStorage.loadLocalStorage()});
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: 'name', sort: 'asc'}]);
    const [activeCustomerFilter, setActiveCustomerFilter] = useState(CustomerFilter.ALL_CUSTOMERS);
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [errors, setErrors] = useState<ErrorResponse[]>([]);
    const {setCurrentCustomer} = useCurrentCustomer();
    const {currentUser} = useCurrentUser();
    const [pageChanged, setPageChanged] = useState(false);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        fetchCustomersData();
    }, [debouncedSearchQuery, activeCustomerFilter, pageChanged, sortModel]);

    const fetchCustomersData = async () => {
        let result;
        setLoading(true);
        try {
            if (activeCustomerFilter === CustomerFilter.ALL_CUSTOMERS && isEmptyString(debouncedSearchQuery)) {
                result = await fetchCustomers(
                    pageModel.page, 
                    pageModel.pageSize,
                    sortModel
                );
            } else if (activeCustomerFilter === CustomerFilter.ALL_CUSTOMERS && !isEmptyString(debouncedSearchQuery)) {
                result = await findCustomerByNameLikeAndActiveIs(
                    encodeURIComponent(debouncedSearchQuery.trimEnd()), 
                    null, 
                    pageModel.page,
                    pageModel.pageSize,
                    sortModel
                );
            } else {
                const active = activeCustomerFilter === CustomerFilter.ACTIVE_CUSTOMERS;
                result = await findCustomerByNameLikeAndActiveIs(
                    encodeURIComponent(debouncedSearchQuery.trimEnd()), 
                    active, 
                    pageModel.page,
                    pageModel.pageSize,
                    sortModel
                );
            }
            const {data, errors, page: {totalElements}} = result;
            setCustomers([...data]);
            setCurrentCustomer({})
            setErrors(errors);
            setTotalElements(totalElements);
        } finally {
            setLoading(false);
        }
    }

    const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value;
        setPageModel({...pageModel, page: 0});
        setCustomers([])
        setSearchQuery(search);
    };

    const handleChangePageModel = (newModel: GridPaginationModel) => {
        if(!loading){
            TableStorage.saveLocalStorage(newModel.pageSize);
            setPageModel(newModel);
            setPageChanged(prev => !prev);
        }
    };

    const handleRadioChange = (event: any) => {
        if(!loading){
            const value = event.target.value;
            setActiveCustomerFilter(value);
            setCustomers([]);
        }
    };

    return (
        <>
        {currentUser && !currentUser.isAdmin && currentUser.customerIds?.length === 1 && <Navigate to={`/customers/${currentUser.customerIds[0]}`}/>}
        {(currentUser.isAdmin || currentUser.customerIds?.length > 1) && 
        <Box sx={{height: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column', gridGap: theme.spacing(2)}}>
            <Box sx={{display: 'grid', gridGap: theme.spacing(2)}}>
                <Box sx={{marginBottom: theme.spacing(2), maxWidth: {xs: '100%', sm: '95%'}}}>
                    <Box sx={{display: "flex", alignItems: "center", gridGap: theme.spacing(1)}}>
                        <BusinessIcon/>
                        <h2>{t("Kunder")}</h2>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs="auto">
                            <SearchBar
                                label={t("Sök_kund")}
                                size="small"
                                onChange={handleSearchQueryChange}
                                searchQuery={searchQuery}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <RadioGroup
                                row
                                aria-labelledby="row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue="Aktiva kunder"
                                value={activeCustomerFilter}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel
                                    value={CustomerFilter.ALL_CUSTOMERS}
                                    control={<Radio/>}
                                    label={t(CustomerFilter.ALL_CUSTOMERS)}
                                />
                                <FormControlLabel
                                    value={CustomerFilter.ACTIVE_CUSTOMERS}
                                    control={<Radio/>}
                                    label={t(CustomerFilter.ACTIVE_CUSTOMERS)}
                                />
                                <FormControlLabel
                                    value={CustomerFilter.INACTIVE_CUSTOMERS}
                                    control={<Radio/>}
                                    label={t(CustomerFilter.INACTIVE_CUSTOMERS)}
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Box>
                {currentUser && currentUser.isAdmin &&
                <Box sx={{maxWidth: {xs: '100%', sm: '95%'}}}>
                    <Grid container spacing={2}>
                        <Grid item xs="auto" sx={{marginLeft: {xs: 0, md: "auto"}}}>
                            <AddCustomerModal/>
                        </Grid>
                    </Grid>
                </Box>
                }
            </Box>
            <Box sx={{maxHeight: '70%', height: 300}}>
                {!isEmptyArray(errors) && errors.map((error, index) => (
                    <Typography key={index} variant="body1" style={{color: 'red'}}>
                        {error.message}
                    </Typography>
                ))}
                {isEmptyArray(errors) && (
                    <CustomersTable
                        customers={customers}
                        loading={loading}
                        paginationModel={pageModel}
                        totalElements={totalElements}
                        onPaginationModelChange={handleChangePageModel}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                    />
                )}
            </Box>
        </Box>
        }
        </>
    );
}
