import { NotificationRegistration } from "../types/NotificationRegistration";
import { ApiResponseSingle, fetchData, getIdTokenHeader} from "./apiUtils";

export async function createNotificationRegistration(registration: NotificationRegistration): Promise<ApiResponseSingle<NotificationRegistration>> {
    const apiUrl = `/api/notifications`;

    const options = {
        headers: getIdTokenHeader(),
        method: "POST",
        body: JSON.stringify(registration)
    };

    const response = fetchData<NotificationRegistration>(apiUrl, options);
    return response as Promise<ApiResponseSingle<NotificationRegistration>>;
}

