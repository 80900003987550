import React, { useState } from "react";
import {Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { passwordResetRequest } from "../authConfig";
import { resetUserPassword } from "../api/UserAPI";
import { useNavigate} from "react-router-dom";

export default function ResetPasswordPage() {

  const {instance} = useMsal();
  const [showReset, setShowReset] = useState(false);
  const [username, setUserName] = useState("");
  const [errors, setErrors] = useState({username: {value: false, message: ''}});
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loginUrl = ():string => {
    return isMobileApp() ? "/realtimeview" : "/";
  }

  const isMobileApp = ():boolean => {
      return window.localStorage.getItem("REGISTRATION_TOKEN") !== null;
  }

  const resetForEmailAddress = () => {
    setShowReset(false);
    instance.loginRedirect({...passwordResetRequest, redirectStartPage: loginUrl()})
  }

  const isValidUsername = (value: string) => {
    return(value && value.match(/^[a-zA-Z]+[-\w\.]*[a-zA-Z\d]+$/));
  }

  const handleInputChange = (e) => {
    setUserName(e.target.value);
    let valid = isValidUsername(e.target.value)
    setErrors({username:{value: !valid, message: valid? "" : "Ogiltigt_Användarnamn"}})
  }

  const clearFormData = () => {
    setUserName("")
    setErrors({username: {value: false, message: ''}})
  }

  const handleClose = () => {
    setShowReset(false)
  }

  const handleSubmit = () => {
    if(isValidUsername(username)){
      clearFormData();
      handleClose();
      resetPassword(username);
    }
  }
  
  const resetPassword = async (username: string) => {
        try {
            setIsLoading(true);
            await resetUserPassword(username);
        } catch (error) {
            console.error('Error when resetting password', error);
        }
        finally {
            setIsLoading(false);
        }
    };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              { t("Återställ_lösenord") }
            </Typography>
            {!showReset && (
            <>
            <Grid item xs={12} textAlign="center">
              <Button  onClick={() => resetForEmailAddress()} color="inherit" variant="text" type="button">{t("Loggar_in_med_emailadress")}</Button>
            </Grid>
            <Grid item xs={12} textAlign="center">
             <Button  onClick={() => setShowReset(true)} color="inherit" variant="text" type="button">{t("Loggar_in_med_anvnamn")}</Button>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button onClick={() => navigate(loginUrl())} color="inherit" variant="text" type="button">{t("Till inloggningen")}</Button>
            </Grid>
            </>
            )}
            <Dialog
              open={showReset}
              onClose={(e) => {clearFormData(); handleClose();}}
              PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    handleSubmit();
                },
              }}
            >
            <DialogTitle sx={{xs:12}} variant="h5">{t("Återställ_lösenord")}</DialogTitle>
            <DialogContent sx={{minWidth: '20vw', minHeight: '20vh'}}>
            <Grid item xs={12} sx={{mt:1}}>
              <TextField
                  id="username"
                  name={t("Användarnamn")}
                  label={t("Användarnamn")}
                  size="small"
                  required
                  value={username}
                  onChange={handleInputChange}
                  error={errors.username.value}
                  helperText={errors.username.value ? t(errors.username.message) : ''}
                  fullWidth
                  autoComplete="off"
              />
            <Typography sx={{pt:2}} variant="body1">{t("Återställning_ledtext")}</Typography>
            </Grid>
            </DialogContent>
            <DialogActions sx={{m: 1}}>
              <Button variant="outlined" color="inherit" type="submit" disabled={isLoading || username.length === 0 || errors.username.value}>{t("Skicka")}</Button>
              <Button variant="outlined" color="error" onClick={()=>{clearFormData(); handleClose();}}>{t("Avbryt")}</Button>
            </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
